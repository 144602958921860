import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBillingHistory,
  fetchBillingHistoryByOwner,
} from "../../../redux/reducers/billingReducer";
import Loader from "../../loader/loader";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import { useTranslation } from "react-i18next";
import { calculateTotalCosts, getCurrencyStr } from "../../../utils/billing";
import { Months } from "../filters/monthSelect";
import { createMonthlyCostsFromBillingRecords } from "./createMonthlyCostsFromBillingRecords";
const Decimal = require("decimal.js");

const OwnerContent = ({ selectedOwner, selectedYear, ...containerProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { billingRecords, fetchingHistory, historyFetchError } = useSelector(
    (state) => state.billing
  );
  const [tableRecords, setTableRecords] = useState([]);
  const [filteredTableRecords, setFilteredTableRecords] = useState([]);
  const [filterText, setFilterText] = useState(null);

  useEffect(() => {
    if (selectedOwner != null && selectedOwner.length > 0) {
      dispatch(clearBillingHistory());
      dispatch(fetchBillingHistoryByOwner(selectedOwner, selectedYear));
    }
  }, [selectedOwner, selectedYear]); // eslint-disable-line

  useEffect(() => {
    const tableRecords = createMonthlyCostsFromBillingRecords(
      selectedOwner,
      billingRecords
    );
    tableRecords.sort((a, b) =>
      (a.accountName || "").localeCompare(b.accountName || "")
    );
    setTableRecords(tableRecords);
    setFilteredTableRecords(tableRecords);
  }, [billingRecords, setTableRecords, setFilteredTableRecords]); // eslint-disable-line

  useEffect(() => {
    if (filterText != null) {
      setFilteredTableRecords(
        tableRecords.filter((tableRecord) =>
          tableRecord.accountName.toLocaleLowerCase().includes(filterText)
        )
      );
    }
  }, [filterText, tableRecords, setFilteredTableRecords]);

  useEffect(() => {
    if (historyFetchError != null) {
      dispatch(
        showSnackbar({
          message: historyFetchError,
          severity: "error",
        })
      );
    }
  }, [historyFetchError, dispatch]);

  const getTitle = () => {
    if (selectedOwner == null || selectedOwner.length === 0) {
      return t("noneSelected");
    }

    const result = calculateTotalCosts(billingRecords);
    return `${selectedOwner} (${selectedYear}): ${new Decimal(
      result.totalCost
    ).toFixed(2)}${getCurrencyStr(result.currency)}`;
  };

  return (
    <Box sx={{ ...containerProps }}>
      <Typography>{getTitle()}</Typography>
      <TextField
        sx={{ my: 1 }}
        label={t("filter")}
        placeholder={t("tilkkaFilterPlaceholder")}
        size="small"
        variant="outlined"
        value={filterText || ""}
        onChange={(e) => setFilterText(e.target.value)}
      />
      <TableContainer sx={{ mt: 1 }} component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>{t("cloud")}</TableCell>
              <TableCell sx={{ fontWeight: "bold" }}>{t("account")}</TableCell>
              {Object.values(Months).map((month) => {
                // Months are 1 to 12
                return (
                  <TableCell key={month} sx={{ fontWeight: "bold" }}>
                    {t(`months.${month}`).substring(0, 3)}
                  </TableCell>
                );
              })}
              <TableCell sx={{ fontWeight: "bold" }}>{t("total")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchingHistory === true && (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            )}
            {fetchingHistory === false && tableRecords.length === 0 && (
              <TableRow>
                <TableCell colSpan={14} align="center">
                  {t("itemsNotFound")}
                </TableCell>
              </TableRow>
            )}
            {fetchingHistory === false &&
              filteredTableRecords.map((tableRecord) => {
                const {
                  accountName,
                  monthlyCostMap,
                  overallCost,
                  currency,
                  service,
                } = tableRecord;
                const currencyStr = getCurrencyStr(currency);
                return (
                  <TableRow key={accountName}>
                    <TableCell>{service}</TableCell>
                    <TableCell>{accountName}</TableCell>
                    {Object.values(Months).map((month) => {
                      // Months are 1 to 12
                      let costStr = "-";
                      const cost = monthlyCostMap.get(month);
                      if (cost != null) {
                        costStr = `${cost || "-"}${currencyStr}`;
                      }
                      return <TableCell key={month}>{costStr}</TableCell>;
                    })}
                    <TableCell>{`${overallCost}${currencyStr}`}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default OwnerContent;
