import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { fetchBillingHistory } from "../../../redux/reducers/billingReducer";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import { RecordDataType, ReportType } from "../../common";
import Authorized from "../../../auth/authorized";
import { Permission } from "../../../auth/permission";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import { White } from "../../../theme/colors";
import { createFileReport } from "../../../redux/reducers/reportReducer";

const BillingAllActions = ({ year, monthNumber }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { creatingReportDataMap, createReportError } = useSelector(
    (state) => state.report
  );
  const { userData } = useSelector((state) => state.user);

  useEffect(() => {
    if (createReportError != null) {
      dispatch(
        showSnackbar({
          message: createReportError,
          severity: "error",
        })
      );
    }
  }, [createReportError, dispatch]);

  const doCreateReport = () => {
    dispatch(
      createFileReport(
        ReportType.BASWARE,
        "ALL",
        year,
        monthNumber,
        "EUR",
        false
      )
    )
      .then(() => {
        dispatch(
          fetchBillingHistory(RecordDataType.DATA_AGGREGATED, null, year)
        );
      })
      .catch((error) =>
        console.error("Failed to update data from cloud", error)
      );
  };

  const fetching = creatingReportDataMap.get(monthNumber) === true;
  return (
    <Authorized user={userData} permission={Permission.BILLING_EDIT}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: 1,
          backgroundColor: White,
          borderRadius: 2,
        }}
      >
        {fetching && (
          <CircularProgress sx={{ mr: 1, color: "primary.light" }} size={22} />
        )}

        <Tooltip title={t("createExcelsTooltip")}>
          <span>
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                doCreateReport();
              }}
              disabled={fetching === true}
            >
              <PostAddOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
    </Authorized>
  );
};

BillingAllActions.propTypes = {
  year: PropTypes.number,
  monthNumber: PropTypes.number,
};

export default BillingAllActions;
