import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBillingHistory,
  fetchBillingHistory,
} from "../../../redux/reducers/billingReducer";
import YearSelect from "../filters/yearSelect";
import ChartToggle, { ToggleValues } from "../filters/chartToggle";
import moment from "moment";
import BillingMonthlyEntry, {
  BillingMonthlyEntryMode,
} from "../billingMonthlyEntry/billingMonthlyEntry";
import BillingSummary from "../billingSummary/billingSummary";
import { RecordDataType, Service } from "../../common";
import Loader from "../../loader/loader";
import BillingServiceChart from "./billingServiceChart";
import BillingByServiceActions from "./billingByServiceActions";
import { useTranslation } from "react-i18next";
import {
  filterZeroCostRecords,
  isCompleteMonthForBillingByService,
} from "../../../utils/billing";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import LabelCheckbox from "../../labelCheckbox/labelCheckbox";

const BillingByService = ({ service, ...containerProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [year, setYear] = useState(moment().year());
  const [toggleValue, setToggleValue] = useState(ToggleValues.DETAILS);
  const { billingRecordsMonthlyMap, fetchingHistory, historyFetchError } =
    useSelector((state) => state.billing);
  const [completeMap, setCompleteMap] = useState(new Map());
  const [hideZeroCost, setHideZeroCost] = useState(true);

  useEffect(() => {
    dispatch(fetchBillingHistory(RecordDataType.DATA, service, year));
  }, [service]); // eslint-disable-line

  useEffect(() => {
    if (historyFetchError != null) {
      dispatch(
        showSnackbar({
          message: historyFetchError,
          severity: "error",
        })
      );
    }
  }, [historyFetchError, dispatch]);

  useEffect(() => {
    if (billingRecordsMonthlyMap.size > 0) {
      let yearComplete = true;
      const completeMap = new Map();
      Array.from(billingRecordsMonthlyMap.keys()).forEach((key) => {
        const entry = billingRecordsMonthlyMap.get(key);
        const complete = isCompleteMonthForBillingByService(
          year,
          key,
          entry.records
        );
        completeMap.set(key, complete);
        yearComplete = complete && yearComplete;
      });
      completeMap.set("year", yearComplete);
      setCompleteMap(completeMap);
    }
  }, [billingRecordsMonthlyMap, setCompleteMap, year]);

  const getRecordCountLabel = () => {
    if (service === Service.AWS) {
      return t("accounts");
    } else if (service === Service.AZURE) {
      return t("subscriptions");
    } else {
      return t("projects");
    }
  };

  const isFuture = (targetYear, targetMonth) => {
    const targetMonthNumber = Number(targetMonth);
    const targetYearNumber = Number(targetYear);
    const now = moment();
    const currentMonth = now.month() + 1;
    const currentYear = now.year();
    return targetYearNumber === currentYear && targetMonthNumber > currentMonth;
  };

  const filterRecords = (records) => {
    if (hideZeroCost) {
      return filterZeroCostRecords(records);
    }
    return records;
  };

  return (
    <Box sx={{ p: 2, ...containerProps }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <YearSelect
          value={year}
          onSelect={(value) => {
            dispatch(clearBillingHistory());
            dispatch(fetchBillingHistory(RecordDataType.DATA, service, value));
            setYear(value);
          }}
        />
        <ChartToggle
          ml={2}
          value={toggleValue}
          onToggle={(newValue) => setToggleValue(newValue)}
        />
        <LabelCheckbox
          ml={2}
          value={hideZeroCost}
          onChange={setHideZeroCost}
          label={t("hideZeroCost")}
        />
      </Box>
      <BillingSummary
        my={2}
        year={year}
        billingRecordsMonthlyMap={billingRecordsMonthlyMap}
        excludeZeroCost={hideZeroCost}
        recordCountLabel={getRecordCountLabel()}
        complete={completeMap.get("year") === true}
      />
      {fetchingHistory === true && <Loader />}
      {fetchingHistory === false &&
        toggleValue === ToggleValues.DETAILS &&
        Array.from(billingRecordsMonthlyMap.keys()).map((key) => {
          const entry = billingRecordsMonthlyMap.get(key);

          // do not show future months
          if (isFuture(year, key)) {
            return null;
          }
          return (
            <Box key={key} sx={{ my: 1 }}>
              <BillingMonthlyEntry
                mode={BillingMonthlyEntryMode.SERVICE}
                totalCost={entry.totalCost}
                originalTotalCost={entry.originalTotalCost}
                currency={entry.currency}
                originalCurrency={entry.originalCurrency}
                monthNumber={key}
                year={year}
                service={service}
                records={filterRecords(entry.records)}
                components={
                  <BillingByServiceActions
                    service={service}
                    year={year}
                    monthNumber={key}
                    currency={entry.currency}
                    originalCurrency={entry.originalCurrency}
                  />
                }
                recordCountLabel={getRecordCountLabel()}
                complete={completeMap.get(key) === true}
              />
            </Box>
          );
        })}
      {fetchingHistory === false && toggleValue === ToggleValues.CHART && (
        <BillingServiceChart
          loading={fetchingHistory}
          billingRecordsMonthlyMap={billingRecordsMonthlyMap}
          service={service}
        />
      )}
    </Box>
  );
};

BillingByService.propTypes = {
  service: PropTypes.string.isRequired,
};

export default BillingByService;
