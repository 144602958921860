import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import YearSelect from "../filters/yearSelect";
import ChartToggle, { ToggleValues } from "../filters/chartToggle";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  clearBillingHistory,
  fetchBillingHistory,
} from "../../../redux/reducers/billingReducer";
import BillingSummary from "../billingSummary/billingSummary";
import BillingAggregatedChart from "./billingAggregatedChart";
import { RecordDataType } from "../../common";
import Loader from "../../loader/loader";
import BillingMonthlyEntry, {
  BillingMonthlyEntryMode,
} from "../billingMonthlyEntry/billingMonthlyEntry";
import { isCompleteMonthForBillingAll } from "../../../utils/billing";
import BillingAllActions from "./billingAllActions";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";

const BillingAll = ({ ...containerProps }) => {
  const dispatch = useDispatch();
  const [year, setYear] = useState(moment().year());
  const [toggleValue, setToggleValue] = useState(ToggleValues.DETAILS);
  const { billingRecordsMonthlyMap, fetchingHistory, historyFetchError } =
    useSelector((state) => state.billing);
  const [completeMap, setCompleteMap] = useState(new Map());

  useEffect(() => {
    dispatch(fetchBillingHistory(RecordDataType.DATA_AGGREGATED, null, year));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (historyFetchError != null) {
      dispatch(
        showSnackbar({
          message: historyFetchError,
          severity: "error",
        })
      );
    }
  }, [historyFetchError, dispatch]);

  useEffect(() => {
    if (billingRecordsMonthlyMap.size > 0) {
      let yearComplete = true;
      const completeMap = new Map();
      Array.from(billingRecordsMonthlyMap.keys()).forEach((key) => {
        const entry = billingRecordsMonthlyMap.get(key);
        const complete = isCompleteMonthForBillingAll(year, key, entry.records);
        completeMap.set(key, complete);
        yearComplete = complete && yearComplete;
      });
      completeMap.set("year", yearComplete);
      setCompleteMap(completeMap);
    }
  }, [billingRecordsMonthlyMap, setCompleteMap, year]);

  const isFuture = (targetYear, targetMonth) => {
    const targetMonthNumber = Number(targetMonth);
    const targetYearNumber = Number(targetYear);
    const now = moment();
    const currentMonth = now.month() + 1;
    const currentYear = now.year();
    return targetYearNumber === currentYear && targetMonthNumber > currentMonth;
  };

  return (
    <Box sx={{ p: 2, ...containerProps }}>
      <YearSelect
        value={year}
        onSelect={(value) => {
          dispatch(clearBillingHistory());
          dispatch(
            fetchBillingHistory(RecordDataType.DATA_AGGREGATED, null, value)
          );
          setYear(value);
        }}
      />
      <ChartToggle
        ml={2}
        value={toggleValue}
        onToggle={(newValue) => setToggleValue(newValue)}
      />
      <BillingSummary
        my={2}
        complete={completeMap.get("year") === true}
        year={year}
        billingRecordsMonthlyMap={billingRecordsMonthlyMap}
        hideRecordCount
      />
      {fetchingHistory === true && <Loader />}
      {fetchingHistory === false &&
        toggleValue === ToggleValues.DETAILS &&
        Array.from(billingRecordsMonthlyMap.keys()).map((key) => {
          const entry = billingRecordsMonthlyMap.get(key);

          // do not show future months
          if (isFuture(year, key)) {
            return null;
          }

          return (
            <Box key={key} sx={{ my: 1 }}>
              <BillingMonthlyEntry
                mode={BillingMonthlyEntryMode.AGGREGATED}
                totalCost={entry.totalCost}
                originalTotalCost={entry.originalTotalCost}
                currency={entry.currency}
                originalCurrency={entry.originalCurrency}
                monthNumber={key}
                year={year}
                service={entry.service}
                records={entry.records}
                complete={completeMap.get(key) === true}
                hideButton
                hideRecordCount
                hideDataUpdatedDate
                components={<BillingAllActions year={year} monthNumber={key} />}
              />
            </Box>
          );
        })}
      {fetchingHistory === false && toggleValue === ToggleValues.CHART && (
        <BillingAggregatedChart
          loading={fetchingHistory}
          billingRecordsMonthlyMap={billingRecordsMonthlyMap}
        />
      )}
    </Box>
  );
};

export default BillingAll;
