import React from "react";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import { CurrencyCode } from "../../common";
import { getCurrencyStr } from "../../../utils/billing";
import { Months } from "../filters/monthSelect";
import Loader from "../../loader/loader";
import Decimal from "decimal.js";

const BillingByAccountTable = ({
  tableRecords,
  fetching,
  hideService,
  hideAccount,
  ...containerProps
}) => {
  const { t } = useTranslation();

  const getAccountName = (record) => {
    const { accountName, accountId } = record;
    return (
      <Box>
        <Typography variant="body1" fontWeight="600">
          {accountName}
        </Typography>
        <Typography variant="body2">{accountId}</Typography>
      </Box>
    );
  };

  return (
    <TableContainer sx={{ ...containerProps }} component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            {hideService !== true && (
              <TableCell sx={{ fontWeight: "bold" }}>{t("service")}</TableCell>
            )}
            {hideAccount !== true && (
              <TableCell sx={{ fontWeight: "bold" }}>{t("account")}</TableCell>
            )}
            {Object.values(Months).map((month, index) => {
              // Months are 1 to 12
              return (
                <TableCell key={month + index} sx={{ fontWeight: "bold" }}>
                  {t(`months.${month}`).substring(0, 3)}
                </TableCell>
              );
            })}
            <TableCell sx={{ fontWeight: "bold" }}>{t("total")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fetching === true && (
            <TableRow>
              <TableCell colSpan={14} align="center">
                <Loader />
              </TableCell>
            </TableRow>
          )}
          {fetching === false && tableRecords.length === 0 && (
            <TableRow>
              <TableCell colSpan={14} align="center">
                {t("itemsNotFound")}
              </TableCell>
            </TableRow>
          )}
          {fetching === false &&
            tableRecords.map((tableRecord, index) => {
              const { accountId, records, overallCost, service } = tableRecord;

              const currencyStr = getCurrencyStr(CurrencyCode.EUR);
              return (
                <TableRow key={accountId + index}>
                  {hideService !== true && <TableCell>{service}</TableCell>}
                  {hideAccount !== true && (
                    <TableCell>{getAccountName(tableRecord)}</TableCell>
                  )}
                  {Object.values(Months).map((month, index) => {
                    // Months are 1 to 12
                    let costStr = "-";
                    const billingRecord = records.find(
                      (r) => r.monthNumber === month
                    );
                    if (billingRecord != null && billingRecord.cost != null) {
                      costStr = `${new Decimal(billingRecord.cost).toFixed(
                        2
                      )}${currencyStr}`;
                    }
                    return <TableCell key={month + index}>{costStr}</TableCell>;
                  })}
                  <TableCell>{`${new Decimal(overallCost).toFixed(
                    2
                  )}${currencyStr}`}</TableCell>
                </TableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BillingByAccountTable.propTypes = {
  records: PropTypes.array,
};

export default BillingByAccountTable;
