import { CurrencyCode, Service } from "../components/common";
import moment from "moment";

const Decimal = require("decimal.js");

export const sortRecordsByTime = (records) => {
  records.sort((a, b) => {
    return new Date(b.createdAt) - new Date(a.createdAt);
  });
  return records;
};

export const sortRecordsByName = (records) => {
  // accountNameCloud is a legacy field
  records.sort((a, b) => {
    const nameA = a.accountNameCloud || a.accountName || a.service;
    const nameB = b.accountNameCloud || b.accountName || b.service;
    return nameA.localeCompare(nameB);
  });
  return records;
};

export const initBillingRecordsMonthlyMap = () => {
  const map = new Map();

  // init 12 months
  for (let monthNumber = 1; monthNumber < 13; monthNumber += 1) {
    map.set(monthNumber, {
      totalCost: 0,
      originalTotalCost: 0,
      currency: CurrencyCode.EUR,
      originalCurrency: CurrencyCode.EUR,
      records: [],
    });
  }

  return map;
};

export const calculateTotalCosts = (records) => {
  const validRecords = records.filter((record) => record.cost != null);
  return validRecords.reduce(
    (acc, next) => {
      return {
        totalCost: new Decimal(acc.totalCost).plus(next.cost).toNumber(),
        currency: next.currency || CurrencyCode.EUR,
        originalTotalCost: new Decimal(acc.originalTotalCost)
          .plus(next.originalCost || next.cost)
          .toNumber(),
        originalCurrency: next.originalCurrency || CurrencyCode.EUR,
      };
    },
    {
      totalCost: 0.0,
      currency: CurrencyCode.EUR,
      originalTotalCost: 0.0,
      originalCurrency: CurrencyCode.EUR,
    },
  );
};

export const updateBillingRecordsMonthlyMap = (map, billingRecords) => {
  const update = (records, newRecord) => {
    const index = records.findIndex((record) => record.id === newRecord.id);
    if (index !== -1) {
      records.splice(index, 1, newRecord);
    } else {
      records.push(newRecord);
    }

    const costs = calculateTotalCosts(records);
    return {
      records: sortRecordsByName(records),
      totalCost: costs.totalCost,
      currency: costs.currency,
      originalTotalCost: costs.originalTotalCost,
      originalCurrency: costs.originalCurrency,
    };
  };

  const result = billingRecords.reduce((acc, next) => {
    const { monthNumber } = next;
    const oldRecords = acc.get(monthNumber).records;
    const {
      records,
      totalCost,
      currency,
      originalTotalCost,
      originalCurrency,
    } = update(oldRecords, next);
    acc.get(monthNumber).records = records;
    acc.get(monthNumber).totalCost = totalCost;
    acc.get(monthNumber).currency = currency;
    acc.get(monthNumber).originalTotalCost = originalTotalCost;
    acc.get(monthNumber).originalCurrency = originalCurrency;
    return acc;
  }, map);

  return result;
};

export const calculateSummaryFromMonthlyMap = (
  map,
  excludeZeroCostRecords = false,
) => {
  const result = Array.from(map.keys()).reduce(
    (acc, next) => {
      const entry = map.get(next);
      return {
        yearlyCost: new Decimal(acc.yearlyCost)
          .plus(entry.totalCost)
          .toNumber(),
        records: acc.records.concat(entry.records),
      };
    },
    {
      yearlyCost: 0,
      records: [],
    },
  );

  let records = result.records;
  if (excludeZeroCostRecords === true) {
    records = filterZeroCostRecords(records);
  }
  const accountIds = records.map((r) => r.accountId);
  const uniqueAccountIds = Array.from(new Set(accountIds));

  return {
    yearlyCost: result.yearlyCost,
    accounts: uniqueAccountIds.length,
  };
};

export const getCurrencyStr = (currencyCode) => {
  if (currencyCode === CurrencyCode.USD) {
    return "$";
  } else if (currencyCode === CurrencyCode.EUR) {
    return "€";
  }
  return "?";
};

export const getEarliestDateFromRecords = (records) => {
  if (records.length !== 0) {
    const sortedRecords = sortRecordsByTime(records);
    return moment(sortedRecords[sortedRecords.length - 1].createdAt);
  }
  return null;
};

export const getLatestDateFromRecords = (records) => {
  if (records.length !== 0) {
    const sortedRecords = sortRecordsByTime(records);
    return moment(sortedRecords[0].createdAt);
  }
  return null;
};

export const getUpdatedDateStr = (records) => {
  const date = getEarliestDateFromRecords(records);
  if (date != null) {
    return date.format("DD.MM.YYYY HH:mm:ss");
  }
  return "-";
};

export const isCompleteMonth = (year, monthNumber, compareTimeMoment) => {
  if (Number(year) < compareTimeMoment.year()) {
    return true;
  } else if (Number(year) === compareTimeMoment.year()) {
    return Number(monthNumber) < compareTimeMoment.month() + 1;
  }
};

export const isCompleteMonthForBillingAll = (year, monthNumber, records) => {
  const allServices = Object.keys(Service).length === records.length;
  let monthComplete = false;
  if (records.length) {
    monthComplete = isCompleteMonth(
      year,
      monthNumber,
      getEarliestDateFromRecords(records),
    );
  }
  return allServices && monthComplete;
};

export const isCompleteMonthForBillingByService = (
  year,
  monthNumber,
  records,
) => {
  if (records.length) {
    return isCompleteMonth(
      year,
      monthNumber,
      getEarliestDateFromRecords(records),
    );
  }
  return false;
};

export const isCompleteMontForReports = (year, monthNumber, records) => {
  if (records.length) {
    return isCompleteMonth(
      year,
      monthNumber,
      getLatestDateFromRecords(records),
    );
  }
  return false;
};

export const filterZeroCostRecords = (records) => {
  return records.filter(
    (record) => new Decimal(record.cost).toFixed(9) !== "0.000000000",
  );
};
