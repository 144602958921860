import {
  CircularProgress,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
} from "@mui/material";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Authorized from "../../../auth/authorized";
import { Permission } from "../../../auth/permission";
import { getBillingData } from "../../../redux/reducers/billingReducer";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import { CurrencyCode, ReportType } from "../../common";
import PostAddOutlinedIcon from "@mui/icons-material/PostAddOutlined";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { White } from "../../../theme/colors";
import { createFileReport } from "../../../redux/reducers/reportReducer";

const BillingByServiceActions = ({
  service,
  year,
  monthNumber,
  currency,
  originalCurrency,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { fetchingBillingDataMap, billingDataFetchError } = useSelector(
    (state) => state.billing
  );
  const { creatingReportDataMap, createReportError } = useSelector(
    (state) => state.report
  );
  const { userData } = useSelector((state) => state.user);
  const [targetCurrency, setTargetCurrency] = useState(CurrencyCode.EUR);

  useEffect(() => {
    if (createReportError != null) {
      dispatch(
        showSnackbar({
          message: createReportError,
          severity: "error",
        })
      );
    } else if (billingDataFetchError != null) {
      dispatch(
        showSnackbar({
          message: billingDataFetchError,
          severity: "error",
        })
      );
    }
  }, [createReportError, billingDataFetchError, dispatch]);

  const renderButtons = () => {
    return (
      <>
        {currency !== originalCurrency && (
          <ToggleButtonGroup
            sx={{ mr: 1 }}
            value={targetCurrency}
            exclusive
            onChange={(e, newValue) => {
              e.stopPropagation();
              setTargetCurrency(newValue);
            }}
          >
            <ToggleButton sx={{ maxHeight: "25px" }} value={currency}>
              {currency}
            </ToggleButton>
            <ToggleButton
              sx={{ maxHeight: "25px" }}
              size="small"
              value={originalCurrency}
            >
              {originalCurrency}
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        <Tooltip title={t("createExcelTooltip")}>
          <span>
            <IconButton
              sx={{ mr: 1 }}
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(
                  createFileReport(
                    ReportType.BASWARE,
                    service,
                    year,
                    monthNumber,
                    targetCurrency,
                    true
                  )
                ).then((report) => {
                  if (report) {
                    window.open(report.urls[0], "_blank");
                  }
                });
              }}
              disabled={fetching === true}
            >
              <PostAddOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
        <Tooltip title={t("updateBillingDataTooltip")}>
          <span>
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(getBillingData(service, year, monthNumber));
              }}
              disabled={fetching === true}
            >
              <RefreshOutlinedIcon />
            </IconButton>
          </span>
        </Tooltip>
      </>
    );
  };

  const fetchingBillingData = fetchingBillingDataMap.get(monthNumber) === true;
  const creatingReport = creatingReportDataMap.get(monthNumber) === true;
  const fetching = fetchingBillingData || creatingReport;
  return (
    <Authorized user={userData} permission={Permission.BILLING_EDIT}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          px: 1,
          backgroundColor: White,
          borderRadius: 2,
        }}
      >
        {fetching && (
          <CircularProgress sx={{ mr: 1, color: "primary.light" }} size={22} />
        )}
        {renderButtons()}
      </Box>
    </Authorized>
  );
};

BillingByServiceActions.propTypes = {
  service: PropTypes.string,
  year: PropTypes.number,
  monthNumber: PropTypes.number,
  currency: PropTypes.string,
  originalCurrency: PropTypes.string,
};

export default BillingByServiceActions;
