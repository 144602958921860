import { Box, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBillingHistory,
  fetchBillingHistory,
} from "../../../redux/reducers/billingReducer";
import moment from "moment";
import { RecordDataType } from "../../common";
import BillingMonthlyEntry, {
  BillingMonthlyEntryMode,
} from "../billingMonthlyEntry/billingMonthlyEntry";
import Loader from "../../loader/loader";
import CreateNewReport from "./createNewReport";
import { isCompleteMontForReports } from "../../../utils/billing";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import Authorized from "../../../auth/authorized";
import { Permission } from "../../../auth/permission";
import YearSelect from "../filters/yearSelect";
import TooltipButton from "../../tooltipButton/tooltipButton";

const Reports = ({ ...containerProps }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { billingRecordsMonthlyMap, fetchingHistory, historyFetchError } =
    useSelector((state) => state.billing);
  const { userData } = useSelector((state) => state.user);
  const [year, setYear] = useState(moment().year());
  const [completeMap, setCompleteMap] = useState(new Map());

  useEffect(() => {
    dispatch(fetchBillingHistory(RecordDataType.REPORT, null, year, null));
  }, []); // eslint-disable-line

  useEffect(() => {
    if (historyFetchError != null) {
      dispatch(
        showSnackbar({
          message: historyFetchError,
          severity: "error",
        })
      );
    }
  }, [historyFetchError, dispatch]);

  useEffect(() => {
    if (billingRecordsMonthlyMap.size > 0) {
      const completeMap = new Map();
      Array.from(billingRecordsMonthlyMap.keys()).forEach((key) => {
        const entry = billingRecordsMonthlyMap.get(key);
        const complete = isCompleteMontForReports(year, key, entry.records);
        completeMap.set(key, complete);
      });
      setCompleteMap(completeMap);
    }
  }, [billingRecordsMonthlyMap, setCompleteMap, year]);

  const isFuture = (targetYear, targetMonth) => {
    const targetMonthNumber = Number(targetMonth);
    const targetYearNumber = Number(targetYear);
    const now = moment();
    const currentMonth = now.month() + 1;
    const currentYear = now.year();
    return targetYearNumber === currentYear && targetMonthNumber > currentMonth;
  };

  return (
    <Paper sx={{ p: 2, ...containerProps }}>
      <Authorized user={userData} permission={Permission.BILLING_EDIT}>
        <CreateNewReport mb={2} />
      </Authorized>
      <Box>
        <Typography variant="subtitle1">{t("reportHistory")}</Typography>
      </Box>
      <Box sx={{ mt: 2, display: "flex", alignItems: "center" }}>
        <YearSelect
          mr={2}
          value={year}
          onSelect={(value) => {
            dispatch(clearBillingHistory());
            dispatch(
              fetchBillingHistory(RecordDataType.REPORT, null, value, null)
            );
            setYear(value);
          }}
        />
        <TooltipButton
          buttonText={t("refresh")}
          onClick={() => {
            dispatch(
              fetchBillingHistory(RecordDataType.REPORT, null, year, null)
            );
          }}
          loading={fetchingHistory}
        />
      </Box>
      {fetchingHistory === true && <Loader />}
      {fetchingHistory === false &&
        Array.from(billingRecordsMonthlyMap.keys()).map((key) => {
          const entry = billingRecordsMonthlyMap.get(key);

          // do not show future months
          if (isFuture(year, key)) {
            return null;
          }

          return (
            <Box key={key} sx={{ my: 2 }}>
              <BillingMonthlyEntry
                mode={BillingMonthlyEntryMode.REPORTS}
                monthNumber={key}
                year={year}
                records={entry.records}
                hideButton
                hideTotalCost
                hideDataUpdatedDate
                recordCountLabel={t("reports")}
                complete={completeMap.get(key) === true}
              />
            </Box>
          );
        })}
    </Paper>
  );
};

export default Reports;
