import { Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import Decimal from "decimal.js";
import { getCurrencyStr } from "../../../utils/billing";
import { CurrencyCode } from "../../common";

const pruneCost = (cost) => {
  const costDecimal = new Decimal(cost);
  if (costDecimal.greaterThan(0.99)) {
    return costDecimal.toFixed(2);
  }
  return costDecimal.toFixed(9); // GCP has the smallest unit of 0.000000001
};

const CostBreakdown = ({ record, ...containerProps }) => {
  const { t } = useTranslation();

  const {
    cost,
    currency,
    discountCost,
    discountCoverage,
    savingsPlanCost,
    savingsPlanCoverage,
  } = record;
  const costStr = pruneCost(cost);
  const currencyStr = getCurrencyStr(currency);
  const overallCostStr = `${costStr}${currencyStr}`;
  const resourceUsageCost = new Decimal(cost)
    .minus(savingsPlanCost || discountCost || 0) // savingsPlanCost is the same as discountCost with older records
    .toFixed(2);
  const resourceUsageCostStr = `${t(
    "usage",
  )}: ${resourceUsageCost}${getCurrencyStr(currency || CurrencyCode.EUR)}`;

  const discountCostStr = new Decimal(
    savingsPlanCost || discountCost || 0, // savingsPlanCost is the same as discountCost with older records
  ).toFixed(2);
  const discountCurrencyStr = getCurrencyStr(currency || CurrencyCode.EUR);
  const discountStr = `${t(
    "discountCostAbr",
  )}: ${discountCostStr}${discountCurrencyStr}`;

  const coverageStr = `${t("discountCoverageAbr")}: ${new Decimal(
    savingsPlanCoverage || discountCoverage || 0, // savingsPlanCoverage is the same as discountCoverage with older records
  ).toFixed(2)}${getCurrencyStr(currency || CurrencyCode.EUR)}`;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", ...containerProps }}>
      <Tooltip title={t("usageCostAndDiscountCost")}>
        <Typography variant="body2" fontWeight="bold">
          {overallCostStr}
        </Typography>
      </Tooltip>
      <Tooltip title={t("usageInfo")}>
        <Typography variant="caption">{resourceUsageCostStr}</Typography>
      </Tooltip>
      <Tooltip title={t("discountCostInfo")}>
        <Typography variant="caption">{discountStr}</Typography>
      </Tooltip>
      <Tooltip title={t("discountCoverageInfo")}>
        <Typography variant="caption">{coverageStr}</Typography>
      </Tooltip>
    </Box>
  );
};

export default CostBreakdown;
