import PropTypes from "prop-types";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useTranslation } from "react-i18next";
import BillingDataTable from "./billingDataTable";
import { useState } from "react";
import StatusIcon, { StatusIconStatus } from "../statusIcon/statusIcon";
import BillingAggregatedTable from "./billingAggregatedTable";
import BillingReportTable from "./billingReportTable";
import { getCurrencyStr, getUpdatedDateStr } from "../../../utils/billing";

const Decimal = require("decimal.js");

export const BillingMonthlyEntryMode = Object.freeze({
  AGGREGATED: "AGGREGATED",
  SERVICE: "SERVICE",
  REPORTS: "REPORTS",
});

const BillingMonthlyEntry = ({
  mode,
  monthNumber,
  totalCost,
  originalTotalCost,
  currency,
  originalCurrency,
  records,
  components,
  hideTotalCost,
  hideTimePeriodIcon,
  hideRecordCount,
  hideDataUpdatedDate,
  recordCountLabel,
  complete,
  ...containerPops
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  return (
    <Accordion
      expanded={open}
      onChange={(_e, value) => setOpen(value)}
      sx={{ ...containerPops }}
    >
      <AccordionSummary
        sx={{
          backgroundColor: open ? "primary.main" : "primary.contrastText",
          color: open ? "primary.contrastText" : "default",
        }}
        expandIcon={
          <ExpandMoreIcon
            sx={{ color: open ? "primary.contrastText" : "default" }}
          />
        }
      >
        {hideTimePeriodIcon !== true && (
          <StatusIcon
            mr={1}
            status={complete ? StatusIconStatus.Ok : StatusIconStatus.Warning}
            okTooltip={t("timePeriodComplete")}
            warningTooltip={t("timePeriodIncomplete")}
          />
        )}
        <Typography sx={{ minWidth: 100 }} variant="subtitle1">
          {`${t(`months.${monthNumber}`)}`}
        </Typography>
        {hideTotalCost !== true && (
          <Typography sx={{ ml: 1, minWidth: 70 }} variant="subtitle1">
            {`${new Decimal(totalCost).toFixed(2)}${getCurrencyStr(currency)}`}
          </Typography>
        )}
        {hideTotalCost !== true && currency !== originalCurrency && (
          <Typography sx={{ ml: 0.5, lineHeight: 2.2 }} variant="caption">
            {`(${new Decimal(originalTotalCost).toFixed(2)}${getCurrencyStr(
              originalCurrency,
            )})`}
          </Typography>
        )}
        {hideRecordCount !== true && (
          <Typography sx={{ ml: 1, lineHeight: 2.2 }} variant="caption">
            {`(${recordCountLabel || t("accounts")}:  ${records.length})`}
          </Typography>
        )}
        {hideDataUpdatedDate !== true && (
          <Typography sx={{ ml: 1, lineHeight: 2.2 }} variant="caption">
            {`(${t("updated")}: ${getUpdatedDateStr(records)})`}
          </Typography>
        )}
        <Box flexGrow={1} />
        {components !== null && components}
      </AccordionSummary>
      <AccordionDetails>
        {mode === BillingMonthlyEntryMode.SERVICE && (
          <BillingDataTable records={records} />
        )}
        {mode === BillingMonthlyEntryMode.AGGREGATED && (
          <BillingAggregatedTable records={records} />
        )}
        {mode === BillingMonthlyEntryMode.REPORTS && (
          <BillingReportTable records={records} />
        )}
      </AccordionDetails>
    </Accordion>
  );
};

BillingMonthlyEntry.propTypes = {
  mode: PropTypes.string,
  monthNumber: PropTypes.number,
  totalCost: PropTypes.number,
  originalTotalCost: PropTypes.number,
  currency: PropTypes.string,
  originalCurrency: PropTypes.string,
  records: PropTypes.array,
  components: PropTypes.any,
  hideTotalCost: PropTypes.bool,
  hideTimePeriodIcon: PropTypes.bool,
  hideRecordCount: PropTypes.bool,
  recordCountLabel: PropTypes.string,
  hideDataUpdatedDate: PropTypes.bool,
  complete: PropTypes.bool,
};

export default BillingMonthlyEntry;
