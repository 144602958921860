import { useState, useEffect } from "react";
import { Box, TextField } from "@mui/material";
import YearSelect from "../filters/yearSelect";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  clearBillingHistory,
  fetchBillingHistoryByAccount,
} from "../../../redux/reducers/billingReducer";
import BillingByAccountTable from "./billingByAccountTable";
import { Service } from "../../common";
import { showSnackbar } from "../../../redux/reducers/notificationReducer";
import ServiceSelect from "../filters/serviceSelect";
import { useTranslation } from "react-i18next";

const BillingByAccount = ({ ...containerProps }) => {
  const { t } = useTranslation();
  const [year, setYear] = useState(moment().year());
  const dispatch = useDispatch();
  const { billingRecords, fetchingHistory, historyFetchError } = useSelector(
    (state) => state.billing
  );
  const [tableRecords, setTableRecords] = useState([]);
  const [filteredTableRecords, setFilteredTableRecords] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [service, setService] = useState(Service.AWS);

  useEffect(() => {
    dispatch(clearBillingHistory());
    dispatch(fetchBillingHistoryByAccount(service, year));
  }, []); // eslint-disable-line

  useEffect(() => {
    const tableRecords = [...billingRecords];
    tableRecords.sort((a, b) => b.overallCost - a.overallCost);
    setTableRecords(tableRecords);
    setFilteredTableRecords(tableRecords);
  }, [billingRecords, setTableRecords]);

  useEffect(() => {
    if (filterText != null) {
      setFilteredTableRecords(
        tableRecords.filter(
          (record) =>
            (record.accountName || "")
              .toLocaleLowerCase()
              .includes(filterText) ||
            (record.accountId || "").toLocaleLowerCase().includes(filterText)
        )
      );
    }
  }, [filterText, tableRecords, setFilteredTableRecords]);

  useEffect(() => {
    if (historyFetchError != null) {
      dispatch(
        showSnackbar({
          message: historyFetchError,
          severity: "error",
        })
      );
    }
  }, [historyFetchError, dispatch]);

  return (
    <Box sx={{ p: 2, ...containerProps }}>
      <Box sx={{ display: "flex" }}>
        <YearSelect
          value={year}
          onSelect={(value) => {
            dispatch(clearBillingHistory());
            dispatch(fetchBillingHistoryByAccount(service, value));
            setYear(value);
          }}
        />
        <ServiceSelect
          mx={2}
          service={service}
          serviceTypes={Service}
          onServiceSelected={(value) => {
            dispatch(clearBillingHistory());
            dispatch(fetchBillingHistoryByAccount(value, year));
            setService(value);
          }}
        />
        <TextField
          sx={{ minWidth: 500 }}
          label={t("filter")}
          placeholder={t("auditLogFilterPlaceholder")}
          size="small"
          variant="outlined"
          value={filterText || ""}
          onChange={(e) => setFilterText(e.target.value)}
        />
      </Box>
      <Box sx={{ my: 2, display: "flex" }}>
        <BillingByAccountTable
          tableRecords={filteredTableRecords}
          fetching={fetchingHistory}
        />
      </Box>
    </Box>
  );
};

BillingByAccount.propTypes = {};

export default BillingByAccount;
