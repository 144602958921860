import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Box, Typography } from "@mui/material";
import { calculateSummaryFromMonthlyMap } from "../../../utils/billing";
import StatusIcon, { StatusIconStatus } from "../statusIcon/statusIcon";
const Decimal = require("decimal.js");

const BillingSummary = ({
  complete,
  billingRecordsMonthlyMap,
  hideRecordCount,
  recordCountLabel,
  excludeZeroCost,
  ...containerPops
}) => {
  const { t } = useTranslation();

  const summary = calculateSummaryFromMonthlyMap(
    billingRecordsMonthlyMap,
    excludeZeroCost,
  );
  return (
    <Box
      sx={{
        backgroundColor: "primary.main",
        display: "flex",
        alignItems: "center",
        p: 2,
        color: "primary.contrastText",
        ...containerPops,
      }}
    >
      <StatusIcon
        mr={1}
        status={complete ? StatusIconStatus.Ok : StatusIconStatus.Warning}
        okTooltip={t("timePeriodComplete")}
        warningTooltip={t("timePeriodIncomplete")}
      />
      <Typography sx={{ minWidth: 100, mr: 1 }}>{t("yearlyCost")}</Typography>
      <Typography sx={{ minWidth: 70 }}>
        {new Decimal(summary.yearlyCost).toFixed(2)}€
      </Typography>
      {hideRecordCount !== true && (
        <Typography variant="caption" sx={{ ml: 1 }}>{`(${
          recordCountLabel || t("accounts")
        }: ${summary.accounts})`}</Typography>
      )}
    </Box>
  );
};

BillingSummary.propTypes = {
  complete: PropTypes.bool,
  billingRecordsMonthlyMap: PropTypes.object,
  hideRecordCount: PropTypes.bool,
  toggleValue: PropTypes.string,
  onToggleValueChanged: PropTypes.func,
  recordCountLabel: PropTypes.string,
};

export default BillingSummary;
