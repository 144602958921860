import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import SortableTableCell from "../../table/sortableTableCell";
import { SortDirection, getComparator } from "../../../utils/table";
import TaxCodeStr from "../tilkka/taxCodeStr";
import CostCenterStr from "../tilkka/costCenterStr";
import PruchaseGroupNumberStr from "../tilkka/purchaseGroupNumberStr";
import GlAccountNumberStr from "../tilkka/glAccountNumberStr";
import OrderNumberStr from "../tilkka/orderNumberStr";
import ProductCodeStr from "../tilkka/productCodeStr";
import CostBreakdown from "./costBreakdown";

const SortProps = Object.freeze({
  ACCOUNT_NAME: "accountName",
  COST: "cost",
  PRODUCT_CODE: "productCode.value",
  COST_CENTER: "costCenter.value",
  ORDER_NUMBER: "orderNumber.value",
  TAX_CODE: "taxCode.value",
  PURCHASE_GROUP_NUMBER: "purchaseGroupNumber.value",
  GL_ACCOUNT_NUMBER: "glAccountNumber.value",
  CREATED: "createdAt",
});

const BillingDataTable = ({ records, ...containerPops }) => {
  const { t } = useTranslation();
  const [sortedRecords, setSortedRecords] = useState([]);
  const [order, setOrder] = useState(SortDirection.Descending);
  const [orderByProp, setOrderByProp] = useState(SortProps.ACCOUNT_NAME);

  useEffect(() => {
    setSortedRecords(
      records.map((record) => {
        return {
          ...record,
          accountName: record.accountNameCloud || record.accountName,
        };
      })
    );
  }, [records, setSortedRecords]); // eslint-disable-line

  const handleRequestSort = (property) => {
    const isAsc = orderByProp === property && order === SortDirection.Ascending;
    const newOrder = isAsc ? SortDirection.Descending : SortDirection.Ascending;
    setOrder(newOrder);
    setOrderByProp(property);
    setSortedRecords(
      [...sortedRecords].sort(getComparator(newOrder, property))
    );
  };

  const getAccountName = (row) => {
    const { accountName, accountId } = row;
    return (
      <Box>
        <Typography variant="body1" fontWeight="600">
          {accountName}
        </Typography>
        <Typography variant="body2">{accountId}</Typography>
      </Box>
    );
  };

  return (
    <TableContainer component={Paper} sx={{ ...containerPops }}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.ACCOUNT_NAME}
              label={t("name")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.COST}
              label={t("costBreakdown")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.TAX_CODE}
              label={t("taxCode")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.COST_CENTER}
              label={t("costCenter")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.PURCHASE_GROUP_NUMBER}
              label={t("purchaseGroupNumber")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.GL_ACCOUNT_NUMBER}
              label={t("glAccountNumber")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.ORDER_NUMBER}
              label={t("orderNumber")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.PRODUCT_CODE}
              label={t("productCode")}
              onSort={handleRequestSort}
            />
            <SortableTableCell
              curretSortedProperty={orderByProp}
              order={order}
              property={SortProps.CREATED}
              label={t("created")}
              onSort={handleRequestSort}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedRecords.length === 0 && (
            <TableRow>
              <TableCell colSpan={5} align="center">
                {t("itemsNotFound")}
              </TableCell>
            </TableRow>
          )}
          {(sortedRecords || []).map((row) => {
            const {
              costCenter,
              purchaseGroupNumber,
              glAccountNumber,
              orderNumber,
              productCode,
              taxCode,
            } = row;

            return (
              <TableRow
                key={row.id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row">
                  {getAccountName(row)}
                </TableCell>
                <TableCell>
                  <CostBreakdown record={row} />
                </TableCell>
                <TableCell>
                  <TaxCodeStr objectValue={taxCode} />
                </TableCell>
                <TableCell>
                  <CostCenterStr objectValue={costCenter} />
                </TableCell>
                <TableCell>
                  <PruchaseGroupNumberStr objectValue={purchaseGroupNumber} />
                </TableCell>
                <TableCell>
                  <GlAccountNumberStr objectValue={glAccountNumber} />
                </TableCell>
                <TableCell>
                  <OrderNumberStr objectValue={orderNumber} />
                </TableCell>
                <TableCell>
                  <ProductCodeStr objectValue={productCode} />
                </TableCell>
                <TableCell>
                  {moment(row.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

BillingDataTable.propTypes = {
  records: PropTypes.array,
};

export default BillingDataTable;
