export const Service = Object.freeze({
  AWS: "AWS",
  AWS_OLD: "AWS_OLD",
  AZURE: "AZURE",
  GCP: "GCP",
});
export const ServiceAll = "ALL";

export const RecordDataType = Object.freeze({
  REPORT: "report",
  DATA: "data",
  DATA_AGGREGATED: "data_aggregated",
});

export const CurrencyCode = Object.freeze({
  USD: "USD",
  EUR: "EUR",
});

export const SapBillingDataType = Object.freeze({
  CostCenter: "costCentre",
  BillingAccountNumber: "billingAccountNumber",
  InternalOrderNumber: "internalOrderNumber",
  TaxCode: "taxCode",
  ProductCode: "productCode",
  PurchaseGroupNumber: "purchaseGroupNumber",
});

export const ReportType = Object.freeze({
  BASWARE: "basware",
  ACCOUNT: "account",
});
