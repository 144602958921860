import { Box, Paper, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getCurrencyStr } from "../../../utils/billing";
import Decimal from "decimal.js";

const DiscountDetails = ({ discountDetails, ...containerProps }) => {
  const { t } = useTranslation();

  const renderLine = (label, value) => {
    return (
      <Box sx={{ my: 0.5, display: "flex", alignItems: "center" }}>
        <Typography variant="body2" sx={{ minWidth: 170 }} fontWeight="600">
          {label}
        </Typography>
        <Typography variant="body2" sx={{ ml: 1 }}>
          {value}
        </Typography>
      </Box>
    );
  };

  const getSum = (sum) => {
    if (sum == null) {
      return 0;
    }
    const decimals = sum < 0.0 ? 9 : 2;
    return new Decimal(sum).toFixed(decimals);
  };

  // sanity
  if (discountDetails == null || Object.keys(discountDetails).length === 0) {
    return null;
  }

  const {
    service,
    accountId,
    accountName,
    purchaseCost,
    currency,
    originalPurchaseCost,
    coverage,
    originalCoverage,
    originalCurrency,
  } = discountDetails;

  const currencyStr = getCurrencyStr(currency);
  const originalCurrencyStr = getCurrencyStr(originalCurrency);
  const sameCurrency = currency === originalCurrency;

  const costStr = `${getSum(purchaseCost)}${currencyStr}`;
  const originalCostStr = `${getSum(originalPurchaseCost)}${originalCurrencyStr}`;
  const purchaseCostStr = sameCurrency
    ? costStr
    : `${costStr} (${originalCostStr})`;

  const coverageStr = `${getSum(coverage)}${currencyStr}`;
  const originalCoverStr = `${getSum(originalCoverage)}${originalCurrencyStr}`;
  const coverageSumStr = sameCurrency
    ? coverageStr
    : `${coverageStr} (${originalCoverStr})`;

  const benefitStr = `${new Decimal(coverage)
    .minus(purchaseCost)
    .toFixed(0)}${currencyStr}`;

  return (
    <Paper sx={{ ...containerProps, p: 2 }}>
      {renderLine(t("service"), service)}
      {renderLine(t("account"), `${accountName} (${accountId})`)}
      {renderLine(t("purchaseCost"), purchaseCostStr)}
      {renderLine(t("discountCoverage"), coverageSumStr)}
      {renderLine(t("costBenefit"), benefitStr)}
    </Paper>
  );
};

export default DiscountDetails;
